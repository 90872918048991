import { ArrowUpIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';

export default function ScrollTop() {
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > window.outerHeight) {
				setShowButton(true);
			} else {
				setShowButton(false);
			}
		});
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // for smoothly scrolling
		});
	};

	return (
		<>
			{showButton && (
				<button
					onClick={scrollToTop}
					type="button"
					title="scroll to top"
					className="fixed bottom-4 right-4 rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:hidden"
				>
					<ArrowUpIcon className="h-5 w-5" aria-hidden="true" />
				</button>
			)}
		</>
	);
}
